import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BgImage } from 'gbimage-bridge';
import { getImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';
import { BLOCKS } from '@contentful/rich-text-types';
import { useAboutUs } from '../../custom-query-hooks/useAboutUs';

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <p className="richText">{ children }</p>,
  },
  /* eslint-disable no-shadow */
  renderText: (text) => text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
};

const AboutUs = function AboutUs() {
  const aboutUs = useAboutUs();
  const query = useStaticQuery(graphql`
    query {
      allFile(filter: {relativePath: {eq: "office-table.png"}}) {
        nodes {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  `);

  const bgImage = getImage(query.allFile.nodes[0].childImageSharp.gatsbyImageData);

  return (
    <section id="about-us" className="about-us-container">
      <h4>{ aboutUs.title }</h4>
      <hr className="line-break" />
      <BgImage className="bg-image about-us-image" image={bgImage} alt="Blurred Office Picture" as="div">
        <div className="about-us-blurb">
          {documentToReactComponents(JSON.parse(aboutUs.blurb.raw), options)}
        </div>
      </BgImage>
    </section>
  );
};

export default AboutUs;
