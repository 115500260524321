import { graphql, useStaticQuery } from 'gatsby';

/* eslint-disable import/prefer-default-export */
export const useSupportIcons = () => {
  const { allContentfulOurSupport } = useStaticQuery(
    graphql`
      {
        allContentfulOurSupport(filter: {title: {eq: "Our Support"}}) {
          nodes {
            title
            iconSections {
              title
              icons {
                title
                description {
                  description
                }
                iconImage {
                  file {
                    url
                  }
                }
              }
            }
          }
        }
      }
    `,
  );
  return allContentfulOurSupport.nodes[0];
};
