import { graphql, useStaticQuery } from 'gatsby';

/* eslint-disable import/prefer-default-export */
export const useAboutUs = () => {
  const { allContentfulAboutUs } = useStaticQuery(
    graphql`
      {
        allContentfulAboutUs(filter: {sys: {contentType: {sys: {id: {eq: "aboutUs"}}}}}) {
          nodes {
            title
            blurb {
              raw
            }
          }
        }
      }
    `,
  );
  return allContentfulAboutUs.nodes[0];
};
