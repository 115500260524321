import React, { useContext } from 'react';
import { BgImage } from 'gbimage-bridge';
import { getImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper/core';
import { useApproachIcons } from '../../custom-query-hooks/useApproachIcons';
import 'swiper/swiper-bundle.css';
import { siteContext } from '../../context/provider';

SwiperCore.use([Navigation]);

const Approach = function Approach() {
  const { isHireTalent } = useContext(siteContext);
  const ourApproach = useApproachIcons();
  const query = useStaticQuery(graphql`
    query {
      allFile (filter: {relativePath: {eq: "woman-standing-office.png"}}) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `);

  const image = getImage(query.allFile.edges[0].node.childImageSharp.gatsbyImageData);

  return (
    <section id="our-approach" className={isHireTalent ? 'approach-container' : 'hidden'}>
      <BgImage
        className="bg-image
      approach-bg"
        image={image}
        alt="Blurred Office Picture"
        as="div"
      >
        <h4>{ ourApproach.title }</h4>
        <div className="approach-mobile">
          <Swiper
            slidesPerView={1}
            navigation
            loop
          >
            {ourApproach.sections.map((section) => (
              <SwiperSlide
                key={section.title}
              >
                <div className="approach-slider">
                  <h5>{section.title}</h5>
                  <hr className="line-break" />
                  {section.icons.map((icon) => (
                    <div
                      key={icon.title}
                      className="section-card"
                    >
                      <img className="icon" src={icon.iconImage.file.url} alt={icon.title} />
                      <p className="icon-title">{icon.title}</p>
                      <p className="icon-description">{icon.description.description}</p>
                    </div>
                  ))}
                  <hr className="line-break" />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="approach-desktop">
          {ourApproach.sections.map((section) => (
            <div
              key={section.title}
              className="approach-slider"
            >
              <h5>{section.title}</h5>
              <hr className="line-break" />
              {section.icons.map((icon) => (
                <div
                  key={icon.title}
                  className="section-card"
                >
                  <img className="icon" src={icon.iconImage.file.url} alt={icon.title} />
                  <p className="icon-title">{icon.title}</p>
                  <p className="icon-description">{icon.description.description}</p>
                </div>
              ))}
              <hr className="line-break" />
            </div>
          ))}
        </div>
      </BgImage>
    </section>
  );
};

export default Approach;
