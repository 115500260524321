import { useState } from 'react';
import axios from 'axios';

const useFormValidation = (formInputs, requiredInputs) => {
  const [inputs, setInputs] = useState(formInputs);
  const [errors, setErrors] = useState({});
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const validate = () => {
    setErrors({});
    const inputErrors = {};
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const prop in requiredInputs) {
      const fieldsToCheck = requiredInputs[prop].validateOn;
      const emptyFields = fieldsToCheck.filter((field) => !inputs[field]);
      if (fieldsToCheck.length === emptyFields.length) {
        inputErrors[prop] = true;
      }
    }
    if (Object.keys(inputErrors).length >= 1) {
      setErrors(inputErrors);
      return false;
    }
    return true;
  };

  const handleChange = (e) => {
    setInputs((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleFileChange = (e) => {
    setInputs((prevState) => ({
      ...prevState,
      files: e.target.files[0],
    }));
  };

  const handleResponse = (status) => {
    if (status === 200) {
      setInputs(formInputs);
      setShowSuccessMessage(true);
    } else {
      // handle other responses
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (e.target.name.value !== '' || e.target.email.value !== '' || e.target.message.value !== '') {
      // console.log('Sneaky!');
      // do something to disregard the message or create custom alert through Google Analytics
    } else if (validate()) {
      setIsLoading(true);
      try {
        const response = await axios.post(
          `https://getform.io/f/${process.env.FORM_SUBMISSION_ENDPOINT}`,
          inputs,
        );
        handleResponse(response.status);
        setIsLoading(false);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('form submission error ', error);
      }
    }
  };

  return {
    errors,
    handleChange,
    handleSubmit,
    handleFileChange,
    inputs,
    isLoading,
    showSuccessMessage,
  };
};

export default useFormValidation;
