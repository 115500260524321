import React, { useContext } from 'react';
import { BgImage } from 'gbimage-bridge';
import { getImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';
import Logo from '../../assets/images/Hero-Logo.png';
import { siteContext } from '../../context/provider';

const Hero = function Hero() {
  const { isHireTalent, handleChange } = useContext(siteContext);
  const query = useStaticQuery(graphql`
    query {
      allFile (filter: {relativeDirectory: {eq: "hero"}}) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `);

  const images = query.allFile.edges;
  const imageStack = [];
  images.forEach((image) => {
    imageStack.push(getImage(image.node.childImageSharp.gatsbyImageData));
  });

  return (
    <section className="hero-section">
      <BgImage className="bg-image" image={imageStack} alt="Blurred Office Picture" as="div">
        <div className="hero-container">
          <div className="hero-text-container">
            <img src={Logo} alt="Nujob Logo" className="hero-logo" />
            <h1>Talent</h1>
            <h1 className="white-hero-text">Matters</h1>
            <h2>How can </h2>
            <h2 className="white-hero-text">we help?</h2>
          </div>
          <div className="hero-selector">
            <button
              type="button"
              className="hero-button grey"
              onClick={() => handleChange(true)}
              onKeyDown={() => handleChange(true)}
            >
              <h3 className="hero-selector-text text-left">Hire Talent</h3>
              <div className={isHireTalent ? 'orange-underline' : 'blue-underline'} />
            </button>
            <button
              type="button"
              className="hero-button blue"
              onClick={() => handleChange(false)}
              onKeyDown={() => handleChange(true)}
            >
              <h3 className="hero-selector-text text-right white-text">Find opportunities</h3>
              <div className={!isHireTalent ? 'orange-underline' : 'white-underline'} />
            </button>
          </div>
        </div>
      </BgImage>
    </section>
  );
};

export default Hero;
