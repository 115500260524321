import React from 'react';
import { Link } from 'gatsby';
import slugify from 'slugify';
import SwiperCore, { Navigation } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useResources } from '../../custom-query-hooks/useResources';

SwiperCore.use([Navigation]);

const Resources = function Resources() {
  const resources = useResources();

  return (
    <section className="resources-section-container">
      <h4>Resources</h4>
      <hr className="line-break" />
      <div className="resources-mobile-section">
        <Swiper
          slidesPerView={1}
          navigation
        >
          {
            resources.map((resource) => {
              const slug = slugify(resource.title, { lower: true });
              return (
                <SwiperSlide
                  key={slug}
                >
                  <Link
                    to={`/resources/${slug}`}
                    title={resource.title}
                    className="resource-tile"
                  >
                    <p className="resource-tile-title">{resource.title}</p>
                    <div className="resource-tile-image-container">
                      <img className="resource-tile-image" src={resource.image.file.url} alt={resource.image.file.fileName} />
                    </div>
                  </Link>
                </SwiperSlide>
              );
            })
          }
        </Swiper>
      </div>
      <div className="resources-desktop-section">
        {resources.map((resource, index) => {
          if (index < 3) {
            const slug = slugify(resource.title, { lower: true });
            return (
              <Link
                key={slug}
                to={`/resources/${slug}`}
                title={resource.title}
              >
                <div className="resources-section-card">
                  <div className="resources-section-title">
                    <p>{resource.title}</p>
                    <p>|</p>
                    <p>{resource.date}</p>
                  </div>
                  <div className="resources-section-image-container">
                    <img
                      className="resource-tile-image"
                      src={resource.image.file.url}
                      alt={resource.image.file.fileName}
                    />
                  </div>
                </div>
              </Link>
            );
          }
          return null;
        })}
      </div>
    </section>
  );
};

export default Resources;
