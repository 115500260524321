import React, { useContext } from 'react';
import { BgImage } from 'gbimage-bridge';
import { getImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';
import 'swiper/swiper-bundle.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper/core';
import { useSupportIcons } from '../../custom-query-hooks/useSupportIcons';
import { siteContext } from '../../context/provider';

SwiperCore.use([Navigation]);

const OurSupport = function OurSupport() {
  const { isHireTalent } = useContext(siteContext);
  const ourSupport = useSupportIcons();
  const query = useStaticQuery(graphql`
    query {
      allFile (filter: {relativePath: {eq: "laptop-image.png"}}) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `);

  const image = getImage(query.allFile.edges[0].node.childImageSharp.gatsbyImageData);

  const swiperSlides = ourSupport.iconSections.map((section) => (
    <SwiperSlide
      key={section.title}
    >
      <div className="approach-slider">
        <h5 className="support-white">{section.title}</h5>
        <hr className="line-break" />
        {section.icons.map((icon) => (
          <div
            key={icon.title}
            className="section-card"
          >
            <img className="icon" src={icon.iconImage.file.url} alt={icon.title} />
            <p className="icon-title support-white">{icon.title}</p>
            <p className="icon-description support-white">{icon.description.description}</p>
          </div>
        ))}
        <hr className="line-break" />
      </div>
    </SwiperSlide>
  ));

  return (
    <section id="our-support" className={!isHireTalent ? 'approach-container' : 'hidden'}>
      <BgImage
        className="bg-image
        approach-bg"
        image={image}
        alt="Blurred Office Picture"
        as="div"
      >
        <h4 className="support-white">{ ourSupport.title }</h4>
        <div className="support-mobile">
          {!isHireTalent
          && (
          <Swiper
            observer
            shouldSwiperUpdate
            slidesPerView={1}
            navigation
            loop
          >
            {swiperSlides}
          </Swiper>
          )}
        </div>
        <div className="support-desktop">
          {ourSupport.iconSections.map((section) => (
            <div
              key={section.title}
              className="approach-slider"
            >
              <h5 className="support-white">{section.title}</h5>
              <hr className="line-break" />
              {section.icons.map((icon) => (
                <div
                  key={icon.title}
                  className="section-card"
                >
                  <img className="icon" src={icon.iconImage.file.url} alt={icon.title} />
                  <p className="icon-title support-white">{icon.title}</p>
                  <p className="icon-description support-white">{icon.description.description}</p>
                </div>
              ))}
              <hr className="line-break" />
            </div>
          ))}
        </div>
      </BgImage>
    </section>
  );
};

export default OurSupport;
