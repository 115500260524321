import { graphql, useStaticQuery } from 'gatsby';

/* eslint-disable import/prefer-default-export */
export const useApproachIcons = () => {
  const { allContentfulOurApproach } = useStaticQuery(
    graphql`
      {
        allContentfulOurApproach(filter: {title: {eq: "Our Approach"}}) {
          nodes {
            title
            sections {
              title
              icons {
                title
                description {
                  description
                }
                iconImage {
                  file {
                    url
                  }
                }
              }
            }
          }
        }
      }
    `,
  );
  return allContentfulOurApproach.nodes[0];
};
