import React from 'react';
import Phone from '../../assets/images/icons/contact phone.svg';
import Location from '../../assets/images/icons/contact location.svg';
import name from '../../assets/images/icons/contact email.svg';
import useFormValidation from '../../custom-hooks/useFormValidation';

const ContactUs = function ContactUs() {
  const formInputs = {
    namejjdsjfh: '', emailsdadas: '', phonedsfsg: '', messagedfsdgsg: '', files: [],
  };
  const requiredInputs = {
    namejjdsjfh: { validateOn: ['namejjdsjfh'] },
    emailsdadas: { validateOn: ['emailsdadas'] },
    phonedsfsg: { validateOn: ['phonedsfsg'] },
    messagedfsdgsg: { validateOn: ['messagedfsdgsg'] },
  };

  const {
    handleChange,
    handleSubmit,
    handleFileChange,
    inputs,
    errors,
    isLoading,
    showSuccessMessage,
  } = useFormValidation(formInputs, requiredInputs);

  return (
    <section id="contact" className="contact-us-container">
      <h4>Contact Us</h4>
      <hr className="line-break" />
      <div className="contact-details-container">
        <div className="contact-details-row">
          <img className="contact-icon" src={Phone} alt="Phone" />
          <p className="bold">Phone</p>
          <p>480.442.0550</p>
        </div>
        <div className="contact-details-row">
          <img className="contact-icon" src={name} alt="Phone" />
          <p className="bold">name</p>
          <p>info@nujob.com</p>
        </div>
        <div className="contact-details-row">
          <img className="contact-icon" src={Location} alt="Phone" />
          <p className="bold">Location</p>
          <p>Alpine, UT</p>
        </div>
      </div>
      <div className="contact-form-container">
        <form
          className="contact-form"
          onSubmit={handleSubmit}
          encType="multipart/form-data"
          method="POST"
          id="NuJobForm"
          acceptCharset="UTF-8"
        >
          <p
            className={!Object.keys(errors).length ? 'empty' : ''}
          >
            {!Object.keys(errors).length ? '\u00A0' : 'Please fill in all fields'}
          </p>
          { showSuccessMessage && <p>Success! Thank you for your message</p>}
          <label className="label" htmlFor="namejjdsjfh">
            <input
              className={errors.namejjdsjfh ? 'form-input red-border' : 'form-input'}
              type="name"
              placeholder="enter your full name"
              name="namejjdsjfh"
              id="namejjdsjfh"
              value={inputs.namejjdsjfh}
              onChange={handleChange}
            />
          </label>
          <label className="label" htmlFor="emailsdadas">
            <input
              className={errors.emailsdadas ? 'form-input red-border' : 'form-input'}
              type="email"
              placeholder="enter your email"
              name="emailsdadas"
              id="emailsdadas"
              value={inputs.emailsdadas}
              onChange={handleChange}
            />
          </label>
          <label className="label" htmlFor="phonedsfsg">
            <input
              className={errors.phonedsfsg ? 'form-input red-border' : 'form-input'}
              type="phone"
              placeholder="enter contact number"
              name="phonedsfsg"
              id="phonedsfsg"
              value={inputs.phonedsfsg}
              onChange={handleChange}
            />
          </label>
          <label className="label" htmlFor="messagedfsdgsg">
            <textarea
              className={errors.messagedfsdgsg ? 'form-input red-border message-input' : 'form-input message-input'}
              placeholder="start typing your message here"
              name="messagedfsdgsg"
              id="messagedfsdgsg"
              value={inputs.messagedfsdgsg}
              onChange={handleChange}
            />
          </label>
          <div className="footer-buttons">
            <label
              htmlFor="file"
              className="form-buttons attach-button"
            >
              <input
                type="file"
                accept=".pdf"
                className="hidden"
                id="file"
                name="file"
                onChange={handleFileChange}
              />
              <p>{inputs.files.length === 0 ? 'Attach file' : 'File Attached'}</p>
            </label>
            {isLoading
              ? (
                <div className="lds-ring">
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
              )
              : (
                <button
                  type="submit"
                  className="form-buttons submit-button"
                >
                  Submit
                </button>
              )}
          </div>
          <div className="footer-form">
            <label htmlFor="name" className="footer-form">
              <input
                type="text"
                id="name"
                name="name"
                placeholder="enter your full name"
                className="footer-form"
              />
            </label>
            <label htmlFor="email" className="footer-form">
              <input
                type="text"
                id="email"
                name="email"
                placeholder="enter your email"
                className="footer-form"
              />
            </label>
            <label htmlFor="phone" className="footer-form">
              <input
                type="text"
                id="phone"
                name="phone"
                placeholder="enter contact number"
                className="footer-form"
              />
            </label>
            <label htmlFor="email" className="footer-form">
              <textarea
                id="message"
                name="message"
                placeholder="start typing your message here"
                className="footer-form"
              />
            </label>
          </div>
        </form>
      </div>
    </section>
  );
};

export default ContactUs;
