import React from 'react';
import Layout from '../components/Layout';
import '../assets/global.css';
import Hero from '../components/sections/Hero';
import Approach from '../components/sections/Approach';
import AboutUs from '../components/sections/AboutUs';
import OurSupport from '../components/sections/OurSupport';
import ContactUs from '../components/sections/ContactUs';
import Resources from '../components/sections/Resources';

const IndexPage = function IndexPage() {
  return (
    <Layout>
      <Hero />
      <Approach />
      <OurSupport />
      <hr className="section-line-break" />
      <AboutUs />
      <hr className="section-line-break" />
      <div className="desktop-section">
        <ContactUs />
        <Resources />
      </div>
    </Layout>
  );
};

export default IndexPage;
